const env = process.env.NODE_ENV || "development";

const domainEnvironment = {
  development: {
        domainAdminSide: "http://localhost:3001",
        domainUserSide: "http://localhost:3001",
        domainName:"",
  },
  production: {
      domainAdminSide: "https://admin.dienbien.cgis.asia",
      domainUserSide: "https://dienbien.cgis.asia",
      domainName:"dienbien.cgis.asia",
  },
};

module.exports = domainEnvironment[env];
